import * as React from "react";

function SvgIcon5(props) {
  return (
    <svg {...props} viewBox="0 0 100 100">
      <g fill="none" fillRule="evenodd" transform="translate(-15)">
        <path fill="#FF9000" d="M20 42h80v60H20z" />
        <path fill="#FFCD00" d="M20 40h80v60H20z" />
        <path fill="#62C8A5" d="M49 72h51v5H49z" />
        <path
          fill="#FF7200"
          d="M67.283 55.123c5.74-8.772 4.832-20.762-2.727-28.514-8.591-8.812-22.52-8.812-31.112 0-7.297 7.484-8.397 18.917-3.299 27.592C31.049 55.74 49 77.25 49 77.25s17.498-20.928 18.283-22.127z"
        />
        <ellipse cx={48.624} cy={40.82} fill="#E2EBF5" rx={7.624} ry={7.82} />
        <ellipse cx={48.624} cy={38.82} fill="#FFF" rx={7.624} ry={7.82} />
        <path
          fill="#FFCD00"
          d="M52 22.575a8.675 8.675 0 010-.15A7.438 7.438 0 0060.424 14a8.675 8.675 0 01.151 0A7.438 7.438 0 0069 22.424a8.675 8.675 0 010 .15A7.438 7.438 0 0060.576 31a8.675 8.675 0 01-.15 0A7.438 7.438 0 0052 22.576zm-37 6.974a5.615 5.615 0 010-.098A4.812 4.812 0 0020.451 24a5.615 5.615 0 01.098 0A4.812 4.812 0 0026 29.451a5.615 5.615 0 010 .098A4.812 4.812 0 0020.549 35a5.615 5.615 0 01-.098 0A4.812 4.812 0 0015 29.549z"
        />
        <path
          fill="#62C8A5"
          d="M24 10.593a10.72 10.72 0 010-.186A9.188 9.188 0 0034.407 0a10.72 10.72 0 01.186 0A9.188 9.188 0 0045 10.407a10.72 10.72 0 010 .186A9.188 9.188 0 0034.593 21a10.72 10.72 0 01-.186 0A9.188 9.188 0 0024 10.593z"
        />
      </g>
    </svg>
  );
}

export default SvgIcon5;
