import * as React from "react";

function SvgIcon1(props) {
  return (
    <svg {...props} viewBox="0 0 100 100">
      <g fill="none" fillRule="evenodd">
        <path fill="#EE451F" d="M0 28h80v60H0z" />
        <path fill="#FF7200" d="M0 26h80v60H0z" />
        <path
          fill="#FFCD00"
          d="M80 52.188V26H0v60h.694l27-27 11.56 11.559L68.811 41 80 52.188z"
        />
        <circle cx={17.5} cy={43.5} r={9.5} fill="#D7E0EA" />
        <circle cx={17.5} cy={41.5} r={9.5} fill="#FFF" />
        <path
          fill="#62C8A5"
          fillRule="nonzero"
          d="M93.582 56.997H71.997c-.553 0-1-.445-1-.994a.997.997 0 011-.993h21.585l-6.355-6.31a.988.988 0 010-1.405 1.005 1.005 0 011.414 0l8.063 8.007a.987.987 0 010 1.405l-8.063 8.007a1.005 1.005 0 01-1.414 0 .988.988 0 010-1.405l6.355-6.311z"
        />
        <path
          fill="#FF7200"
          d="M67 25.62a13.783 13.783 0 010-.24c.551.08 1.115.12 1.688.12 6.523 0 11.812-5.289 11.812-11.813 0-.572-.04-1.136-.12-1.686a13.783 13.783 0 01.24 0c-.08.55-.12 1.114-.12 1.687 0 6.523 5.289 11.812 11.813 11.812.572 0 1.136-.04 1.686-.12a13.783 13.783 0 010 .24 11.913 11.913 0 00-1.686-.12c-6.524 0-11.813 5.289-11.813 11.813 0 .572.04 1.136.12 1.686a13.783 13.783 0 01-.24 0c.08-.55.12-1.114.12-1.687 0-6.523-5.289-11.812-11.813-11.812-.572 0-1.136.04-1.686.12z"
        />
        <path
          fill="#62C8A5"
          d="M62 5.549a5.615 5.615 0 010-.098A4.812 4.812 0 0067.451 0a5.615 5.615 0 01.098 0A4.812 4.812 0 0073 5.451a5.615 5.615 0 010 .098A4.812 4.812 0 0067.549 11a5.615 5.615 0 01-.098 0A4.812 4.812 0 0062 5.549z"
        />
        <path
          fill="#FFCD00"
          d="M32 13.575a8.675 8.675 0 010-.15A7.438 7.438 0 0040.424 5a8.678 8.678 0 01.151 0A7.438 7.438 0 0049 13.424a8.675 8.675 0 010 .15A7.438 7.438 0 0040.576 22a8.675 8.675 0 01-.15 0A7.438 7.438 0 0032 13.576z"
        />
      </g>
    </svg>
  );
}

export default SvgIcon1;
