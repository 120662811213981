import * as React from "react";

function SvgIcon3(props) {
  return (
    <svg {...props} viewBox="0 0 100 100">
      <g fill="none" fillRule="evenodd">
        <path
          stroke="#62C8A5"
          strokeWidth={2}
          d="M10 24.003c0-1.104.9-2.003 2.002-2.003h86.996c1.108 0 2.002.892 2.002 2.003v76.994c0 1.104-.9 2.003-2.002 2.003H12.002A1.998 1.998 0 0110 100.997V24.003z"
        />
        <path fill="#EE451F" d="M19 33h73v53H19z" />
        <path fill="#FF7200" d="M19 31h73v53H19z" />
        <path
          fill="#FFCD00"
          d="M92 54.484V31H19v53.1h.395L43.78 59.714l10.74 10.739 26.725-26.725L92 54.484z"
        />
        <ellipse cx={35.513} cy={46.141} fill="#E2EBF5" rx={8.694} ry={8.408} />
        <ellipse cx={35.513} cy={44.141} fill="#FFF" rx={8.694} ry={8.408} />
        <path
          fill="#FFCD00"
          d="M0 100.115a13.272 13.272 0 010-.23c.531.076 1.073.115 1.625.115C7.907 100 13 94.907 13 88.625c0-.552-.04-1.094-.115-1.625a13.273 13.273 0 01.23 0A11.459 11.459 0 0013 88.625C13 94.907 18.093 100 24.375 100c.552 0 1.094-.04 1.625-.115a13.273 13.273 0 010 .23 11.472 11.472 0 00-1.625-.115C18.093 100 13 105.093 13 111.375c0 .552.04 1.094.115 1.625a13.293 13.293 0 01-.23 0c.076-.531.115-1.073.115-1.625C13 105.093 7.907 100 1.625 100c-.552 0-1.094.04-1.625.115z"
        />
        <path
          fill="#FF7200"
          d="M80 21.566a7.655 7.655 0 010-.132A6.562 6.562 0 0087.433 14a7.655 7.655 0 01.133 0A6.562 6.562 0 0095 21.433a7.655 7.655 0 010 .132A6.562 6.562 0 0087.567 29a7.655 7.655 0 01-.132 0A6.562 6.562 0 0080 21.567z"
        />
        <path
          fill="#62C8A5"
          d="M56 5.549a5.615 5.615 0 010-.098A4.812 4.812 0 0061.451 0a5.615 5.615 0 01.098 0A4.812 4.812 0 0067 5.451a5.615 5.615 0 010 .098A4.812 4.812 0 0061.549 11a5.615 5.615 0 01-.098 0A4.812 4.812 0 0056 5.549z"
        />
      </g>
    </svg>
  );
}

export default SvgIcon3;
